import Vue from 'vue'

//do not animate on mobile or tablet
var minScreenWidth = 769;
var paperScript
var callbacks = []

// Utility functions to create shapes
function createPathsArray (pathsArray, lineCount, pathToClone) {
  for (let j = 0; j < lineCount - 1; j++) {
    pathsArray.push(pathToClone.clone())
  }

  return pathsArray
}

function populateConfig (configArray, lineCount, lineHeight) {
  for (let w = 0; w < lineCount; w++) {
    configArray.push(
      {
        speed: (Math.random().toFixed(1) * 1) + .2,
        height: (Math.random().toFixed(1) * 3 + 1) * lineHeight
      }
    )
  }

  return configArray
}

var loadPaper = function (callback) {
  if (paperScript === undefined) {
    paperScript = document.createElement('script')
    paperScript.src = '//cdnjs.cloudflare.com/ajax/libs/paper.js/0.11.5/paper-full.min.js'
    paperScript.async = false;
    paperScript.onload = function() {
      paper.install(window);
      callbacks.forEach(cb => cb());
    }
    callbacks.push(callback)
    document.body.appendChild(paperScript)
  } else {
    if (window.paper === undefined) {
      callbacks.push(callback)
    } else {
      callback()
    }
  }
}

Vue.directive('wave-card', {
  inserted: (el, binding) => {
    let {value} = binding

    // The amount of segment points per line we want to create :
    let amount = 12
    // The maximum height of the wave:
    let height = value.height || 20
    // Number of lines to create
    let lineCount = value.lineCount || 30

    let pathsArray = []
    let configArray = []

    loadPaper(function() {
      // Create an empty project and a view for the canvas:
      paper.setup(el)

      // Create First path and style it:
      let path = new paper.Path({
        strokeColor: value.color || 'rgba(255,255,255, 0.6)',
        strokeWidth: 1,
        strokeCap: 'square'
      })

      pathsArray.push(path)

      pathsArray = createPathsArray(pathsArray, lineCount, path)
      configArray = populateConfig(configArray, lineCount, height)

      pathsArray.map(({ index }) => {
        addSegments(index)
      })

      function addSegmentPoint (idx, pathToUpdate) {
        let newPoint = new paper.Point(idx / amount, 1)
        let { width, height } = paper.view.size
        pathToUpdate.add(newPoint.x * width, newPoint.y * height)
      }
      
      // Add segment points to the path spread out
      // over the width of the view:
      function addSegments (indexForPath) {
        for (var segPoint = 0; segPoint <= amount; segPoint++) {
          addSegmentPoint(segPoint, pathsArray[indexForPath])
        }
      }
      
      function movePathSegments ({ time }, pathIndex) {
        let offset = height * (pathIndex + 1)
        for (var i = 0; i <= amount; i++) {
          let segment = pathsArray[pathIndex].segments[i]

          // A cylic value between -1 and 1
          let sinus = Math.sin((time - timeOffset) * configArray[pathIndex].speed + i)

          // Change the y position of the segment point:
          segment.point.y = sinus * configArray[pathIndex].height + offset
        }
      }

      //need to update the X coordinate on all the points (that are inside segments inside paths) after a resize, 
      //because they were calculated from the canvas width, which changes
      function updateAfterResize(view) {
        for (let i = 0; i < pathsArray.length; i++) {
          let path = pathsArray[i];

          for(let j = 0; j < path.segments.length; j++) {
            let segment = path.segments[j];
              segment.point.x = segment.index * view.size.width / amount;
          }
        }
      }

      let hoverElement = el.parentElement
      let view = paper.View._viewsById[el.id] // Get a reference to this specific View
      let timeOffset = 0
      let timeEnd

      // Setup default
      for (let i = 0; i < pathsArray.length; i++) {
        movePathSegments({ time: 0 }, i)
      }

      hoverElement.addEventListener("mouseenter", function (e) {
        view._project.activate() // Animate on the correct Project (for this View)
        //console.log("start animating", paper.view)
        if (window.innerWidth < minScreenWidth) {
          return // Disable animation at breakpoint
        }
        timeOffset += (Date.now() - (timeEnd || Date.now())) / 1000
        paper.view.onFrame = function(event) {
          for (let i = 0; i < pathsArray.length; i++) {
            movePathSegments(event, i)
          }
        }
      });

      paper.view.onResize = function(event) {
        updateAfterResize(view);
      }

      hoverElement.addEventListener("mouseleave", function (e) {
        timeEnd = Date.now()
        //console.log("stop animating", paper.view)
        paper.view.onFrame = undefined
      });
    })
  }
})
